<template>
  <div class="item">
    <div class="game">
      <span>俄罗斯</span>
      <span class="time">17:00</span>
    </div>
    <div class="team home">
      <div class="name">符拉迪沃斯托克迪那摩</div>
    </div>
    <div class="vs">VS</div>
    <div class="team guest">
      <div class="name">辛历克</div>
    </div>
    <div class="btn">
      <van-button v-if="props.mode === 'confirm'" type="primary" size="mini">确认</van-button>
      <van-button v-if="props.mode === 'delete'" type="danger" size="mini">删除</van-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  mode:{
    type:String,
    default:'delete', // delete confirm
  }
})
</script>

<style scoped>
.item{
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}
.item .game{
  color: #003309;
  width: 23%;
  flex: 0 0 23%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.item .team{
  width: 28%;
  flex: 0 0 28%;
  display: flex;
  align-items: center;
}
.item .team .name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}
.item .team.home{
  justify-content: flex-end;
}
.item .vs{
  width: 11%;
  flex: 0 0 11%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666971;
  font-size: 12px;
}
</style>