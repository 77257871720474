<template>
  <div class="page-container">
    <!-- 标题栏 -->
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">HG映射列表</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>


    <van-list
        class="content"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <card-view v-for="item in games"
                 :key="item.systemGameId"
                 @click="openDialog(item)"
      >

        <template #title>
          <div class="card-title">
            <span>{{item.sourceTeam1Name}} vs {{item.sourceTeam2Name}}</span>

            <van-button square type="primary" size="small" @click="recheckMapping(item)">重刷映射</van-button>
          </div>
        </template>
        <div>开赛时间: {{ item.startDt }}</div>
        <div :class="{ nomatch: !item.leagueName }">系统联赛名称: {{ item.leagueName || '未匹配' }}</div>
        <div>HG 联赛名称: {{ item.sourceLeagueName }}</div>
        <div :class="{ nomatch: !item.team1Name }">系统队1名称: {{ item.team1Name || '未匹配' }}</div>
        <div>HG 队1名称: {{ item.sourceTeam1Name }}</div>
        <div :class="{ nomatch: !item.team2Name }">系统队2名称: {{ item.team2Name || '未匹配' }}</div>
        <div>HG 队2名称: {{ item.sourceTeam2Name }}</div>
        <div>系统Id: {{ item.systemGameId }}</div>
        <div>HG-EcId: {{ item.ecId }}</div>
      </card-view>
      <van-divider />
    </van-list>


  <van-dialog
      v-model:show="showDialog"
      title="比赛详情"
      show-confirm-button
      show-cancel-button
      @confirm="onSave"
      @cancel="onCancel"
      @close="onCancel"
  >
    <van-field v-model="currentGame.sourceLeagueName" label="源联赛名称" disabled />

    <SearchSelect
        label="系统赛类"
        v-model="currentLeague"
        placeholder="请输入联赛名称"
        custom-value-key="leagueId"
        :is-enabled-clear="isEnabledClear"
        :search="searchLeagues"
        :on-add="openCreateLeagueDialog"
    />

    <van-field v-model="currentGame.sourceTeam1Name" label="源队1名称" disabled />
    <SearchSelect
        label="系统队1名称"
        v-model="currentTeam1"
        placeholder="请输入队伍名称"
        custom-value-key="teamId"
        :is-enabled-clear="isEnabledClear"
        :search="searchTeams"
        :on-add="openCreateTeamDialog1"
    />

    <van-field v-model="currentGame.sourceTeam2Name" label="源队2名称" disabled />
    <SearchSelect
        label="系统队2名称"
        v-model="currentTeam2"
        placeholder="请输入队伍名称"
        custom-value-key="teamId"
        :is-enabled-clear="isEnabledClear"
        :search="searchTeams"
        :on-add="openCreateTeamDialog2"
    />
  </van-dialog>

  <van-dialog
      v-model:show="showCreateDialog"
      title="新建项"
      show-confirm-button
      show-cancel-button
      @confirm="onCreateConfirm"
  >
    <van-field
        v-model="createNameObj.level"
        label="Level"
        required
        type="number"
        placeholder="大于0的,为热门"
    />
    <van-field
        v-model="createNameObj.color"
        label="颜色"
        placeholder="输入颜色值:如 #FFF"
    />
    <van-field
        v-model="createNameObj.twName"
        label="繁体"
        required
        placeholder="请输入名称"
    />
    <van-field
        v-model="createNameObj.cnName"
        label="简体"
        placeholder="请输入名称"
    />
    <van-field
        v-model="createNameObj.enName"
        label="英文"
        placeholder="请输入名称"
    />
    <van-field
        v-model="createNameObj.thName"
        label="泰文"
        placeholder="请输入名称"
    />
  </van-dialog>

    <van-popup v-model:show="sendWordShow" position="bottom" round teleport="body">
      <van-picker :columns="list" :columns-field-names="customFieldName" @confirm="onConfirm" @cancel="onCancel">
        <template v-slot:title>
          <van-search v-model="keyValue"  autocomplete="off" :placeholder="props.placeholder" shape="round" :clearable="false" @update:model-value="onSearch" />
        </template>
      </van-picker>
    </van-popup>
</div>
</template>

<script setup>
import { ref,toRaw } from 'vue';
import {get,post} from '/src/utils/request'
import CardView from "@/components/CardView.vue";
import SearchSelect from '@/components/SearchSelect.vue';
import {showFailToast, showSuccessToast} from "vant";
import { showNotify } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
//const isRefreshing=ref(false);

const games = ref([]);
const loading = ref(false);
const finished = ref(false);
const showDialog = ref(false);
const currentGame = ref({});
const showCreateDialog = ref(false);
const isEnabledClear = ref(true);
const currentLeague=ref({value:0,text:''})
const currentTeam1=ref({value:0,text:''})
const currentTeam2=ref({value:0,text:''})

const currentCreateType=ref(0)

const errorCount=ref(0)

const createNameObj=ref({ cnName:'',twName:'',enName:'',thName:"",level:0,color:""})

const onLoad = async () => {
loading.value = true;
try {
  if(errorCount.value) return

  errorCount.value = errorCount.value + 1;
  const newMatches = await get("/AdminApi/Mapping/GetHQMissList");
  games.value = [...newMatches];
  loading.value = false;
  finished.value = true;
} catch (error) {
  loading.value = false;
  console.error('加载数据出错:', error);
}
finally {
  loading.value = false;
}
};

/*
const onRefresh=async ()=>{
isRefreshing.value = true;
try {
  const newMatches = await get("/AdminApi/Mapping/GetHQMissList");
  games.value = [...newMatches];

  finished.value = true;
} catch (error) {
  isRefreshing.value = false;
  console.error('加载数据出错:', error);
}
finally {
  isRefreshing.value = false;
}
} */

const openDialog = (match) => {
console.log('Received match:', match); // 打印 match，确认其是否为有效对象
if (!match || typeof match !== 'object') {
  console.error('Invalid match data:', match);
  return;
}
let v={ ...toRaw(match) }
currentGame.value = v;
currentLeague.value={ value:v.leagueId,text:v.leagueName}
currentTeam1.value={ value:v.team1Id,text:v.team1Name}
currentTeam2.value={ value:v.team2Id,text:v.team2Name}
isEnabledClear.value= false
showDialog.value = true;
};

const searchLeagues = async (query) => {
try {
  return (await get('/adminapi/league/GetList',{keyword: query})).data;
} catch (error) {
  console.error('搜索联赛时出错:', error);
}
};

const searchTeams = async (query) => {
try {
  return  (await get('/adminapi/team/GetList',{keyword:query})).data;
} catch (error) {
  console.error('搜索队伍时出错:', error);
}
};

const openCreateLeagueDialog = () => {
currentCreateType.value=1
createNameObj.value.twName= currentGame.value.sourceLeagueName
createNameObj.value.cnName=currentGame.value.sourceLeagueName

showCreateDialog.value = true;
};

const openCreateTeamDialog1 = () => {
currentCreateType.value=2
createNameObj.value.twName=currentGame.value.sourceTeam1Name
createNameObj.value.cnName=currentGame.value.sourceTeam1Name

showCreateDialog.value = true;
};

const openCreateTeamDialog2 = () => {
currentCreateType.value=2
createNameObj.value.twName=currentGame.value.sourceTeam2Name
createNameObj.value.cnName=currentGame.value.sourceTeam2Name

showCreateDialog.value = true;
};

const onCreateConfirm = async () => {
if (!createNameObj.value.twName){
  showNotify({ type: 'warning', message: '请输入繁体名称' });
  return
}

try {
  let url=currentCreateType.value===1?'/AdminApi/League/UpdateLeague':'/AdminApi/Team/UpdateTeam'

  const result =await post(url, {...toRaw(createNameObj.value)});
  if (result.isSuccess) {
    showSuccessToast("创建成功")
    showCreateDialog.value = false;
    createNameObj.value={}
  }
  else {
    showFailToast("创建失败:" + result.message)
  }
} catch (error) {
  console.error('创建项时出错:', error);
}
};

const onSave =async () => {
if (!currentLeague.value.value
    && !currentTeam1.value.value
    && !currentTeam2.value.value
) {
  console.error('所有参数均未映射');
  return;
}

loading.value=true

try {
  const result = await post('/AdminApi/Mapping/UpdateHGMissData',{
    sourceEcId:currentGame.value.ecId,
    leagueId:currentLeague.value.value,
    team1Id:currentTeam1.value.value,
    team2Id:currentTeam2.value.value
  })

  if (result.isSuccess){
    showSuccessToast('更新成功');
    isEnabledClear.value= true
    showDialog.value = false;
    window.location.reload()
  }
  else {
    showFailToast("更新失败:"+ result.message);
  }
}
catch (error) {
  showFailToast('更新失败,http错误');

}
finally {
  loading.value=false
}

// 处理保存逻辑
isEnabledClear.value= true
showDialog.value = false;
console.log('保存的数据', currentGame.value);
};

const onCancel = () => {
isEnabledClear.value= true
showDialog.value = false;
}


const recheckMapping= async (item)=>{

  loading.value=true

  try {
    const result = await get('/AdminApi/Mapping/ReCheckGameById?gameId='+item.systemGameId )

    if (result.isSuccess){
      showSuccessToast('刷新成功');
      isEnabledClear.value= true
      showDialog.value = false;
      window.location.reload()
    }
    else {
      showFailToast("更新失败:"+ result.message);
    }
  }
  catch (error) {
    showFailToast('更新失败,http错误');

  }
  finally {
    loading.value=false
  }

}

const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}
</script>

<style scoped>
.nomatch{
color:red;
}

</style>
