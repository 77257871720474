<template>
  <div class="d-flex cell">
    <van-field 
      v-model="inputValue.text"
      v-bind="$attrs"
      readonly
      autocomplete="off"
      :placeholder="props.placeholder"
      @click="sendWordOpen" />
    <div class="cell-right d-flex-xy-center" @click="handleAdd">
      <van-icon name="plus" />  
    </div>
  </div>

  <van-popup v-model:show="sendWordShow" position="bottom" round teleport="body">
    <van-picker :columns="list" :columns-field-names="customFieldName" @confirm="onConfirm" @cancel="onCancel">
      <template v-slot:title>
        <van-search v-model="keyValue"  autocomplete="off" :placeholder="props.placeholder" shape="round" :clearable="false" @update:model-value="onSearch" />
      </template>
    </van-picker>
  </van-popup>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, watch } from 'vue'

const emits = defineEmits(["update:modelValue"])
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  placeholder:{
    type: String,
    default: ''
  },
  search: {
    type: Function, // 远程搜索函数
    default: null,
  },
  onAdd: {
    type: Function,
    default: null,
  },
  customValueKey:{
    type: String,
    default: ''
  },
  isEnabledClear: {
    type: Boolean,
    default: false,
  }
})
const inputValue = reactive({
  text: '',
  value: 0,
})
const customFieldName = {
    text: 'twName',
    value: props.customValueKey,
}
const list = ref([])
const keyValue = ref('')
const sendWordShow = ref(false)

const sendWordOpen = () => {
  sendWordShow.value = true
}
const useDebounce = (callback, delay = 300) => {
  let timer = null

  return function () {
    if(timer) clearTimeout(timer)

    timer = setTimeout(() => {
      callback.apply(this, arguments)
    },delay)
  }
}
const onSearch = useDebounce((value) => {
  if(props.search){
    props.search(value).then(res => {
      list.value = res
    })
  }
})

const setDefault = () =>{
  inputValue.text = props.modelValue.text
  inputValue.value = props.modelValue.value
  
  if(props.modelValue.text && sendWordShow.value){
    keyValue.value = props.modelValue.text
    onSearch(props.modelValue.text)
  }
}

watch(() => props.modelValue, () => {
  setDefault()
},{ deep: true, immediate: true })

watch(() => props.isEnabledClear, (bol) => {
  if(bol){
    keyValue.value = ''
    list.value = []
  }
})

const onConfirm=(e)=>{
  sendWordShow.value = false

  if (e.selectedOptions.length > 0 && e.selectedOptions[0]){
    emits('update:modelValue', {
      text: e.selectedOptions[0].cnName,
      value: e.selectedOptions[0][props.customValueKey],
    })
  }


}
const onCancel=()=>{
  sendWordShow.value = false
}
const handleAdd=()=>{
  if (props.onAdd) {
    props.onAdd();  // 调用传入的新增处理事件
  }
}
</script>

<style scoped>
.d-flex{
  display: flex
}
.d-flex-xy-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell-right{
  padding-right: var(--van-cell-horizontal-padding);
  padding-left: var(--van-padding-xs);
}
</style>