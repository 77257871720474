<template>
  <div class="page-container">
    <!-- 标题栏 -->
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">{{getKeywordType()}}赛类列表</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>
    <div class="search-bar">

      <van-search v-model="searchKeyword" @search="searchLeagues" clearable placeholder="搜索赛类"  class="custom-search" style="padding: 0;flex: 1;" />
      <van-button type="primary" size="small" @click="searchLeagues"  style="margin-left: 5px;">搜索</van-button>
    </div>
    <div class="search-bar" style="padding-top: 0px;padding-bottom: 3px;">
      <van-button type="primary"  @click="openDialog(null)" >新增</van-button>
    </div>

    <van-list
        class="content"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <card-view v-for="item in leagues"
                 :key="item.leagueId"
                 @click="openDialog(item)"

      >
        <template #title>
          <div class="card-title">
            <span :class="{nomatch:item.leagueId<=0}">{{item.leagueId}}-{{item.twName}}</span>
            <van-icon
                name="delete"
                class="delete-icon"
                @click.stop="deleteLeague(item)"
            />
          </div>
        </template>

        <van-field v-model="item.level" label="Level" readonly />
        <van-field v-model="item.cnName" label="简体短标题" readonly />
        <van-field v-model="item.twName" label="繁体短标题" readonly />
        <van-field label="QT" v-model="item.QTName" readonly />
        <van-field label="HG" v-model="item.HGName" readonly />
        <van-field label="Bet365" v-model="item.Bet365Name" readonly />
      </card-view>
      <van-divider />
    </van-list>

    <van-dialog
        v-model:show="showCreateDialog"
        title="详情"
        show-confirm-button
        show-cancel-button
        @confirm="onCreateConfirm"
        @cancel="onCancel"
    >
      <van-field
          v-model="createNameObj.level"
          label="Level"
          required
          type="number"
          placeholder="大于0的,为热门"
      />
      <van-field
          v-model="createNameObj.color"
          label="颜色"
          placeholder="输入颜色值:如 #FFF"
      />
      <van-field
          v-model="createNameObj.twName"
          label="繁体短标题"
          required
          placeholder="请输入名称"
      />
      <van-field
          v-model="createNameObj.cnName"
          label="简体短标题"
          required
          placeholder="请输入名称"
      />
      <van-field
          v-model="createNameObj.enName"
          label="英文短标题"
          placeholder="请输入名称"
      />
      <van-field
          v-model="createNameObj.thName"
          label="泰文短标题"
          placeholder="请输入名称"
      />

      <van-field
          v-model="createNameObj.fullTwName"
          label="繁体长标题"
          placeholder="请输入名称"
      />
      <van-field
          v-model="createNameObj.fullCnName"
          label="简体长标题"
          placeholder="请输入名称"
      />
      <van-field
          v-model="createNameObj.fullEnName"
          label="英文长标题"
          placeholder="请输入名称"
      />
      <van-field
          v-model="createNameObj.fullThName"
          label="泰文长标题"
          placeholder="请输入名称"
      />
    </van-dialog>
  </div>
</template>

<script setup>
import { ref,toRaw } from 'vue';
import {get,post} from '/src/utils/request'
import CardView from "@/components/CardView.vue";
import {showConfirmDialog, showFailToast, showSuccessToast} from "vant";
import { showNotify } from 'vant';
import {useRoute, useRouter} from 'vue-router';

const router = useRouter();
const route=useRoute()
//const isRefreshing=ref(false);
const leagues = ref([]);
const loading = ref(false);
const finished = ref(false);
const showDialog = ref(false);
const showCreateDialog = ref(false);
const isEnabledClear = ref(false);
const searchKeyword=ref(route.query?.keyword??"");
const searchDataSourceType=ref(route.query?.type??-1);
const isToday=ref((route.query?.today??0)===1);
const errorCount=ref(0)

const createNameObj=ref({ cnName:'',twName:'',enName:'',thName:"",level:0,color:""})
/*
const onRefresh=async ()=>{
  isRefreshing.value = true;
  try {
    await searchLeagues();
    finished.value = true;
  } catch (error) {
    isRefreshing.value = false;
    console.error('加载数据出错:', error);
  }
  finally {
    isRefreshing.value = false;
  }
} */

const onLoad = async () => {
  loading.value = true;
  try {
    if(errorCount.value) return

    errorCount.value = errorCount.value + 1;
    await searchLeagues();
    loading.value = false;
    finished.value = true;
  } catch (error) {
    loading.value = false;
    console.error('加载数据出错:', error);
  }
  finally {
    loading.value = false;
  }
};

const openDialog = (match) => {

  if (match){
    //if (match.leagueId===-1){
      localStorage.setItem('leagueTempJsonData', JSON.stringify(match));
    //}

    router.push("/league/detail?leagueId="+"-1" )//+match.leagueId)
  }
  else {
    router.push("/league/detail")
  }


  /*
   let v={}
   if (match){
     v={ ...toRaw(match) }
   }

  createNameObj.value = v;
  isEnabledClear.value= false
  showCreateDialog.value = true; */
};

const onCreateConfirm = async () => {
  if (!createNameObj.value.twName){
    showNotify({ type: 'warning', message: '请输入繁体名称' });
    return
  }

  try {
    let url='/AdminApi/League/UpdateLeague' ;

    const result =await post(url, {...toRaw(createNameObj.value)});
    if (result.isSuccess) {
      showSuccessToast("创建成功")
      await searchLeagues();
      showCreateDialog.value = false;
      createNameObj.value={}
    }
    else {
      showFailToast("创建失败:" + result.message)
    }
  } catch (error) {
    console.error('创建项时出错:', error);
  }
};

const onCancel = () => {
  isEnabledClear.value= true
  showDialog.value = false;
}

const searchLeagues = async () => {
  //loadingAvailable.value = true;
  const result = await fetchLeagues(searchKeyword.value,searchDataSourceType.value);
  leagues.value = result.map(item => ({
    ...item,
    QTName:getMappingName(item,0),
    HGName:getMappingName(item,1),
    Bet365Name:getMappingName(item,2),
    checked: false,
  }));
  //loadingAvailable.value = false;
};

const fetchLeagues = async (keyword,type=-1) => {
  let url='';

  if (isToday.value){
    url='/adminapi/league/GetList'
  }
  else {
    url='/adminapi/league/GetListFromDataSource'
    type-=1;
  }

  try {
    return (await get(url,{keyword: keyword,keywordType:type})).data;
  } catch (error) {
    console.error('搜索联赛时出错:', error);
  }
  return [];
};

const deleteLeague=async (item)=>{
  if (!item){
    return;
  }

  if(await showConfirmDialog({
    showCancelButton:true,
    showConfirmButton:true,
    message:`确定要删除${item.twName}吗?` ,
    title:"删除确认"
  })){
    loading.value=true;

    try {
      let ret=await post('/adminapi/league/DeleteLeague',{leagueId: item.leagueId})
      loading.value=false;

      if (!ret){
        showFailToast("请求失败");
      }
      else {
        if (ret.isSuccess){
          showSuccessToast("操作成功")
          await searchLeagues();
        }
        else {
          showFailToast("操作失败:" + ret.message)
          await searchLeagues();
        }
      }
    }
    catch (e) {
      loading.value=false;

      showFailToast("请求失败2:" + e.message);
    }
  }
}

const getKeywordType=()=>{
  switch (searchDataSourceType.value) {
    case '-1':{
      return '系统'
    }
    case '1':{
      return "QT"
    }
    case "2":{
      return "HG"
    }
    case "3":{
      return "365";
    }
  }
  return ""
}

const getMappingName=(row,type)=>{
  return row.relationships.filter(x=>x.source===type).map(x=>x.sourceName).join(',')

}

const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}
</script>

<style scoped>

.custom-item-title{
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-item-content{
  padding:5px
}

.van-field{
  padding:5px
}

.nomatch{
  color:red;
}
.search-bar {
  display: flex;
  align-items: center;
  padding: 10px;
}
.custom-search {
  flex: 1;
  padding: 0;
  border: 1px solid #ccc; /* 添加边框 */
  border-radius: 4px;      /* 圆角 */
  background-color: #f9f9f9; /* 背景颜色 */
  padding: 5px;            /* 内边距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
}

.custom-search .van-field__control {
  background-color: transparent; /* 控制输入框的背景色 */
  padding-left: 8px;             /* 内边距 */
}

.custom-search .van-field__clear {
  margin-right: 8px;             /* 清除按钮的内边距 */
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-icon {
  color: red;
  cursor: pointer;
}
</style>
