<template>
  <div class="page-container">
    <!-- 标题栏 -->
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">队伍详情</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>
    <van-form @submit="onSubmit">

      <van-cell-group inset title="名称">
        <van-field
            v-model="formData.twName"
            label="繁体"
            required
            placeholder="请输入名称"
        />
        <van-field
            v-model="formData.cnName"
            label="简体"
            required
            placeholder="请输入名称"
        />
        <van-field
            v-model="formData.enName"
            label="英文"
            placeholder="请输入名称"
        />
        <van-field
            v-model="formData.thName"
            label="泰文"
            placeholder="请输入名称"
        />
      </van-cell-group>

      <template v-for="(item ,index) in formData.relationships" :key="index">
        <van-cell-group inset :title="formatRelationTitle(item)">
          <van-field
              v-model="item.sourceId"
              label="源Id"
              placeholder="请输入Id"
          />
          <van-field
              v-model="item.sourceName"
              label="源名称"
              placeholder="请输入名称"
          />
        </van-cell-group>
      </template>


      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>

    </van-form>

  </div>
</template>

<script setup>
import {onMounted, ref, toRaw} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {get, post} from "@/utils/request";
import {showFailToast, showNotify, showSuccessToast} from "vant";

const route = useRoute();
const router = useRouter();
const formData=ref({relationships:[]})
const teamId = route.query.teamId || "";

onMounted(async ()=>{
  await loadData()
})

const loadData=async ()=>{
  let team;

  if (teamId){
    team=await get('/adminapi/team/GetById',{teamId: teamId})
  }

  if (!team){
    formData.value.relationships=[
      {
        source:0,
        sourceId:"",
        sourceName:""
      },
      {
        source:1,
        sourceId:"",
        sourceName:""
      },
      {
        source:2,
        sourceId:"",
        sourceName:""
      },
      {
        source:3,
        sourceId:"",
        sourceName:""
      }
    ]
  }
  else {
    formData.value=team;
  }
}

const formatRelationTitle=(item)=>{
  switch (item.source){
    case 0:
    {
      return "球探映射"
    }
    case 1:{
      return "皇冠映射"
    }
    case 2:{
      return "365映射"
    }
    case 3:{
      return "sbo映射"
    }
    case 4:{
      return "7M映射"
    }
  }
}

const onSubmit=async ()=>{
  if (!formData.value.twName){
    showNotify({ type: 'warning', message: '请输入繁体名称' });
    return
  }

  try {
    let url='/AdminApi/Team/UpdateTeam' ;

    const result =await post(url, {...toRaw(formData.value)});
    if (result.isSuccess) {
      showSuccessToast("操作成功")
      router.replace('/team/index');
    }
    else {
      showFailToast("操作失败:" + result.message)
    }
  } catch (error) {
    showFailToast('创建项时出错:', error.message);
  }
}

const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}
</script>
<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 70px; /* 确保不会被按钮遮挡 */
}

.form-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 100px; /* 提供额外的底部空间，避免被按钮遮挡 */
}

.icon-back, .icon-home {
  font-size: 24px;
  cursor: pointer;
}

.title-text {
  font-size: 18px;
  font-weight: bold;
}
</style>