<template>
  <div class="login-container">
    <div class="login-box">
      <h2 class="login-title">欢迎登录</h2>
      <van-form @submit="onSubmit">
        <van-field v-model="username" label="用户名" required class="input-field" />
        <van-field v-model="password" type="password" label="密码" required class="input-field" />
        <!-- 验证码输入框 -->
        <van-field v-model="captcha" label="验证码" required class="input-field">
          <template #input>
            <div class="captcha-container">
              <input v-model="captcha" placeholder="请输入验证码" required />
              <img :src="imageData" @click="refreshCaptcha" class="captcha-image" alt="验证码" />
            </div>
          </template>
        </van-field>
        <van-button type="primary" block native-type="submit" class="login-button">登录</van-button>
        <van-overlay v-if="isLoading" class="overlay">
          <van-loading size="24px" type="spinner">加载中...</van-loading>
        </van-overlay>
      </van-form>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import { useRouter } from 'vue-router';
import { post,get } from '@/utils/request';
import { showSuccessToast,showFailToast} from 'vant'; // 引入 Vant 的 Toast

export default {
  name:"LoginPage",
  setup() {
    const username = ref('');
    const password = ref('');
    const captcha=ref('');
    const router = useRouter();
    const isLoading = ref(false);
    let loginkey="";
    const imageData=ref('');

    const refreshCaptcha =async () => {
      // 获取新的验证码图片，这里用时间戳防止缓存
      let data=await get(`/AdminApi/LoginAccount/Captcha?t=${new Date().getTime()}`)
      imageData.value="data:image/png;base64," + data.imageData;
      loginkey=data.loginKey
    };

    onMounted(()=>{
      refreshCaptcha()
    })

    const onSubmit =async () => {
      let data={ userName: username.value, password: password.value,captcha:captcha.value,loginKey : loginkey };

      isLoading.value=true;

      try {
        let ret=await post("/AdminApi/LoginAccount/Login",data);

        if(ret.isSuccess){
          localStorage.setItem('token', ret.returnData);
          showSuccessToast('登录成功');
          await router.replace("/")
        }
        else {
          showFailToast('登录失败:' + ret.message)

        }
      }
      catch (error) {
        showFailToast(error);
      }
      finally {
        isLoading.value=false;
      }

    };

    return {
      username,
      password,
      imageData,
      captcha,
      isLoading,
      onSubmit,
      refreshCaptcha
    };
  }
};
</script>
<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  width: 320px;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-title {
  margin-bottom: 24px;
  font-size: 24px;
  color: #333;
}

.input-field {
  margin-bottom: 16px;
}

.captcha-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.captcha-image {
  width: 100px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
}

.login-button {
  margin-top: 20px;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

</style>