<template>
  <div class="page-container">
    <!-- 标题栏 -->
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">Bet365映射列表</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>


    <van-list
        class="content"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <card-view v-for="item in games"
                 :key="item.sourceGameId"
                 @click="openDialog(item,false)"
      >

        <template #title>
          <div class="card-title">
            <span>{{item.sourceTwTeam1Name}} vs {{item.sourceTwTeam2Name}}</span>

            <van-button square type="primary" size="small" @click.stop="onShowHGMapping(item)">映射HG</van-button>
          </div>
        </template>
        <div>开赛时间: {{ formatDateWithoutYearMonth(item.gameStartDt) }}</div>
        <div :class="{ nomatch: !item.leagueName }">系统联赛名称: {{ item.leagueName || '未匹配' }}</div>
        <div>365 联赛名称: {{ item.sourceLeagueName }}</div>
        <div :class="{ nomatch: !item.team1Name }">系统队1名称: {{ item.team1Name || '未匹配' }}</div>
        <div>365 队1名称: {{ item.sourceTwTeam1Name }}</div>
        <div :class="{ nomatch: !item.team2Name }">系统队2名称: {{ item.team2Name || '未匹配' }}</div>
        <div>365 队2名称: {{ item.sourceTwTeam2Name }}</div>
        <div>系统Id: {{ item.systemGameId }}</div>
        <div>Bet365: {{ item.sourceGameId }}</div>
      </card-view>
      <van-divider />
    </van-list>


  <van-dialog
      v-model:show="showDialog"
      title="比赛详情"
      show-confirm-button
      show-cancel-button
      @confirm="onSave"
      @cancel="onCancel"
      @close="onCancel"
  >
    <van-field v-model="currentGame.sourceLeagueName" label="源联赛名称" disabled />

    <SearchSelect
        label="系统赛类"
        v-model="currentLeague"
        placeholder="请输入联赛名称"
        custom-value-key="leagueId"
        :is-enabled-clear="isEnabledClear"
        :search="searchLeagues"
        :on-add="openCreateLeagueDialog"
    />

    <van-field v-model="currentGame.sourceTwTeam1Name" label="源队1名称" disabled />
    <SearchSelect
        label="系统队1名称"
        v-model="currentTeam1"
        placeholder="请输入队伍名称"
        custom-value-key="teamId"
        :is-enabled-clear="isEnabledClear"
        :search="searchTeams"
        :on-add="openCreateTeamDialog1"
    />

    <van-field v-model="currentGame.sourceTwTeam2Name" label="源队2名称" disabled />
    <SearchSelect
        label="系统队2名称"
        v-model="currentTeam2"
        placeholder="请输入队伍名称"
        custom-value-key="teamId"
        :is-enabled-clear="isEnabledClear"
        :search="searchTeams"
        :on-add="openCreateTeamDialog2"
    />

    <van-button type="warning" style="margin-bottom: 10px;" size="small" @click.stop="swapTeam(currentGame)">交换队伍</van-button>
  </van-dialog>

  <van-dialog
      v-model:show="showCreateDialog"
      title="新建项"
      show-confirm-button
      show-cancel-button
      @confirm="onCreateConfirm"
  >
    <van-field
        v-model="createNameObj.level"
        label="Level"
        required
        type="number"
        placeholder="大于0的,为热门"
    />
    <van-field
        v-model="createNameObj.color"
        label="颜色"
        placeholder="输入颜色值:如 #FFF"
    />
    <van-field
        v-model="createNameObj.twName"
        label="繁体"
        required
        placeholder="请输入名称"
    />
    <van-field
        v-model="createNameObj.cnName"
        label="简体"
        placeholder="请输入名称"
    />
    <van-field
        v-model="createNameObj.enName"
        label="英文"
        placeholder="请输入名称"
    />
    <van-field
        v-model="createNameObj.thName"
        label="泰文"
        placeholder="请输入名称"
    />
  </van-dialog>

    <van-popup v-model:show="showHGMappings" position="bottom" round teleport="body">
      <van-picker :columns="hgList"  visible-option-num="10" :columns-field-names="{text:'title',value:'title'}"  title="映射HG比赛" @confirm="onHGConfirm" @cancel="showHGMappings=false">
        <template #option="item">
          <van-row>
            <van-col :span="24" class="van-hairline--right">
              <p style="text-align: center">
                {{formatDateWithoutYearMonth(item.startDt)}}:{{ item.team1Name }} vs {{item.team2Name}}
              </p>
            </van-col>
          </van-row>
        </template>
      </van-picker>
    </van-popup>
</div>
</template>

<script setup>
import { ref,toRaw } from 'vue';
import {get,post} from '/src/utils/request'
import CardView from "@/components/CardView.vue";
import SearchSelect from '@/components/SearchSelect.vue';
import {showFailToast, showSuccessToast} from "vant";
import { showNotify } from 'vant';
import { useRouter } from 'vue-router';

const router = useRouter();
//const isRefreshing=ref(false);

const games = ref([]);
const loading = ref(false);
const finished = ref(false);
const showDialog = ref(false);
const currentGame = ref({});
const showCreateDialog = ref(false);
const isEnabledClear = ref(true);
const currentLeague=ref({value:0,text:''})
const currentTeam1=ref({value:0,text:''})
const currentTeam2=ref({value:0,text:''})
const showHGMappings=ref(false);
const hgList=ref([])
const needSwap=ref(false)

const currentCreateType=ref(0)

const errorCount=ref(0)

const createNameObj=ref({ cnName:'',twName:'',enName:'',thName:"",level:0,color:""})

const onLoad = async () => {
loading.value = true;
try {
  if(errorCount.value) return

  errorCount.value = errorCount.value + 1;
  const newMatches = await get("/AdminApi/Mapping/GetBet365MissList");
  games.value = [...newMatches];
  loading.value = false;
  finished.value = true;
} catch (error) {
  loading.value = false;
  console.error('加载数据出错:', error);
}
finally {
  loading.value = false;
}
};

/*
const onRefresh=async ()=>{
isRefreshing.value = true;
try {
  const newMatches = await get("/AdminApi/Mapping/GetHQMissList");
  games.value = [...newMatches];

  finished.value = true;
} catch (error) {
  isRefreshing.value = false;
  console.error('加载数据出错:', error);
}
finally {
  isRefreshing.value = false;
}
} */

const openDialog = (match,isShowSwap=false) => {
console.log('Received match:', match); // 打印 match，确认其是否为有效对象
if (!match || typeof match !== 'object') {
  console.error('Invalid match data:', match);
  return;
}

needSwap.value=isShowSwap

let v={ ...toRaw(match) }
currentGame.value = v;
currentLeague.value={ value:v.leagueId,text:v.leagueName}
currentTeam1.value={ value:v.team1Id,text:v.team1Name}
currentTeam2.value={ value:v.team2Id,text:v.team2Name}
isEnabledClear.value= false
showDialog.value = true;
};

const searchLeagues = async (query) => {
try {
  return (await get('/adminapi/league/GetList',{keyword: query})).data;
} catch (error) {
  console.error('搜索联赛时出错:', error);
}
};

const searchTeams = async (query) => {
try {
  return  (await get('/adminapi/team/GetList',{keyword:query})).data;
} catch (error) {
  console.error('搜索队伍时出错:', error);
}
};

const openCreateLeagueDialog = () => {
currentCreateType.value=1
createNameObj.value.twName= currentGame.value.sourceLeagueName
createNameObj.value.cnName=currentGame.value.sourceLeagueName

showCreateDialog.value = true;
};

const openCreateTeamDialog1 = () => {
currentCreateType.value=2
createNameObj.value.twName=currentGame.value.sourceTwTeam1Name
createNameObj.value.cnName=currentGame.value.sourceTwTeam1Name

showCreateDialog.value = true;
};

const openCreateTeamDialog2 = () => {
currentCreateType.value=2
createNameObj.value.twName=currentGame.value.sourceTwTeam2Name
createNameObj.value.cnName=currentGame.value.sourceTwTeam2Name

showCreateDialog.value = true;
};

const onCreateConfirm = async () => {
if (!createNameObj.value.twName){
  showNotify({ type: 'warning', message: '请输入繁体名称' });
  return
}

try {
  let url=currentCreateType.value===1?'/AdminApi/League/UpdateLeague':'/AdminApi/Team/UpdateTeam'

  const result =await post(url, {...toRaw(createNameObj.value)});
  if (result.isSuccess) {
    showSuccessToast("创建成功")
    showCreateDialog.value = false;
    createNameObj.value={}
  }
  else {
    showFailToast("创建失败:" + result.message)
  }
} catch (error) {
  console.error('创建项时出错:', error);
}
};

const onSave =async () => {
if (!currentLeague.value.value
    && !currentTeam1.value.value
    && !currentTeam2.value.value
) {
  console.error('所有参数均未映射');
  return;
}

loading.value=true

try {
  const result = await post('/AdminApi/Mapping/UpdateBet365MissData',{
    sourceGameId:currentGame.value.sourceGameId,
    leagueId:currentLeague.value.value,
    team1Id:currentTeam1.value.value,
    team2Id:currentTeam2.value.value,
    sysGameId:currentGame.value.sysGameId
  })

  if (result.isSuccess){
    showSuccessToast('更新成功');
    isEnabledClear.value= true
    showDialog.value = false;
    window.location.reload()
  }
  else {
    showFailToast("更新失败:"+ result.message);
  }
}
catch (error) {
  showFailToast('更新失败,http错误');

}
finally {
  loading.value=false
}

// 处理保存逻辑
isEnabledClear.value= true
showDialog.value = false;
console.log('保存的数据', currentGame.value);
};

const onCancel = () => {
isEnabledClear.value= true
showDialog.value = false;
}

const onShowHGMapping =async (item) => {
  let startDt= addHoursAndFormat(item.gameStartDt,-6)
  let endDt= addHoursAndFormat(item.gameStartDt,6)

  let newMatches = await get("/AdminApi/Mapping/GetHQMissList",{startDt:startDt,endDt:endDt});

  newMatches=newMatches.filter(x=>x.leagueId>0 && x.team1Id>0 && x.team2Id>0);

  for (let i=0;i<newMatches.length;i++){
    newMatches[i].title=`${newMatches[i].team1Name} vs ${newMatches[i].team2Name}`
  }
  currentGame.value=item
  hgList.value = [...newMatches];
  showHGMappings.value=true
}

const onHGConfirm=(e)=>{
  if (e.selectedOptions.length > 0 && e.selectedOptions[0]){
    const item=e.selectedOptions[0]

    let v={...toRaw(currentGame.value)}
    v.leagueId=item.leagueId;
    v.team1Id=item.team1Id;
    v.team2Id=item.team2Id;
    v.leagueName=item.leagueName;
    v.team1Name=item.team1Name;
    v.team2Name=item.team2Name;
    v.sysGameId=item.sysGameId

    currentGame.value=v


    showHGMappings.value=false;
    openDialog(currentGame.value,true)

  }


}

const swapTeam=(game)=>{
  let r={...toRaw(game)}

  const team1Id=r.team1Id
  const team1Name=r.team1Name;

  r.team1Id=r.team2Id;
  r.team2Id=team1Id;
  r.team1Name=r.team2Name;
  r.team2Name=team1Name

  openDialog(r,true)
}

function addHoursAndFormat(dateStr, hoursToAdd) {
  // 将日期字符串转换为 Date 对象
  let date = new Date(dateStr);

  // 增加指定的小时数
  date.setHours(date.getHours() + hoursToAdd);

  // 获取各个部分
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0');  // 月份从 0 开始，所以要加 1
  let day = String(date.getDate()).padStart(2, '0');
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let seconds = String(date.getSeconds()).padStart(2, '0');

  // 返回格式化后的字符串
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const formatDateWithoutYearMonth=(dateStr)=>{
  let date = new Date(dateStr);

  //let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0');  // 月份从 0 开始，所以要加 1
  let day = String(date.getDate()).padStart(2, '0');
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  //let seconds = String(date.getSeconds()).padStart(2, '0');

  // 返回格式化后的字符串
  return `${month}-${day} ${hours}:${minutes}`;
}

/*
const recheckMapping= async (item)=>{

  loading.value=true

  try {
    const result = await get('/AdminApi/Mapping/ReCheckGameById?gameId='+item.systemGameId )

    if (result.isSuccess){
      showSuccessToast('刷新成功');
      isEnabledClear.value= true
      showDialog.value = false;
      window.location.reload()
    }
    else {
      showFailToast("更新失败:"+ result.message);
    }
  }
  catch (error) {
    showFailToast('更新失败,http错误');

  }
  finally {
    loading.value=false
  }

}
*/
const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}
</script>

<style scoped>
.nomatch{
color:red;
}

</style>
