<template>
  <div class="page-container">
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">赛类查询</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>

    <div v-for="(item, index) in searchItems" :key="index" class="search-item">
      <div class="search-title">{{ item.title }}</div>
      <van-field
          v-model="item.value"
          label-width="50px"
          placeholder="请输入Id或者名称关键字"
          :style="{ marginRight: '10px' }"
      />
      <van-button type="primary" @click="onSearch(item.type, item.value)">搜索</van-button>
      <van-button type="primary" v-if="item.type!=='-1'" @click="onSearchToday(item.type, item.value)">今日</van-button>

    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
import {useRouter} from "vue-router";
import {showFailToast} from "vant";

const router = useRouter();


const searchItems = ref([
  { title: '系统', value: '',type:"-1" },
  { title: 'QT', value: '',type:"1" },
  { title: 'HG', value: '',type:"2" },
  { title: '365', value: '',type:"3" },
])

const onSearch =async (type, value) => {
  if (!value){
    showFailToast("请输入查询关键字");
    return
  }
  await router.push(`/league/index?keyword=${encodeURIComponent(value)}&type=${type}`);
}

const onSearchToday =async (type, value) => {

  await router.push(`/league/index?keyword=${encodeURIComponent(value)}&type=${type}&today=1`);
}

const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 全屏高度 */
  padding: 20px;
  box-sizing: border-box;
}

.search-item {
  display: flex;
  align-items: center;
  margin: 10px 0; /* 每个搜索框之间的间距 */
}

.search-title {
  margin-right: 10px; /* 标题和搜索框之间的间距 */
}
</style>
