<template>
  <div class="container">
    <!-- 总标题栏 -->
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">焦点赛类</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>

    <!-- 已选赛类 -->
    <div class="selected-category">
      <div class="sub-header">
        <h3>已选赛类</h3>
        <van-button type="primary" size="small" @click="saveSelection">保存</van-button>
      </div>
      <section class="sub-content">
        <van-cell
            v-for="(item,index) in selectedItems"
            :key="item.leagueId"
        >
          <template #title>
            {{ item.leagueId }}:{{item.twName}}
          </template>
          <template #right-icon>
            <van-icon name="delete" class="search-icon" @click="removeSelected(index)"/>
          </template>

        </van-cell>
      </section>
    </div>

    <!-- 待选赛类 -->
    <div class="available-category">
      <div class="sub-header">
        <h3>待选赛类</h3>
        <van-search v-model="keyword" @search="searchLeagues" placeholder="搜索赛类" style="padding: 0" />
      </div>
      <section class="sub-content">
        <van-cell
            v-for="item in availableItems"
            :key="item.leagueId"
        >
          <template #title>
            <span :class="{selectedLeague:checkSelected(item)}">{{ item.twName }}</span>
          </template>
          <template #right-icon>
            <van-icon name="sort" class="search-icon" @click="toggleSelection(item)"/>
          </template>
        </van-cell>
      </section>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted } from 'vue';
import {showFailToast, showSuccessToast} from 'vant';
import {get, post} from "@/utils/request";
import { useRouter } from 'vue-router';

const router = useRouter();

const selectedItems = ref([]);
const availableItems = ref([]);
const keyword = ref('');
// const loadingSelected = ref(false);
const loadingAvailable = ref(false);
const loading = ref(false);
const finished = ref(false);
const errorCount=ref(0)

const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}

const saveSelection =async () => {
  loading.value=true

  try {
    const result = await post('/AdminApi/Settings/UpdateFocalLeagueIds',{
      leagueIds: selectedItems.value.map(item=>item.leagueId),
    })

    if (result.isSuccess){
      showSuccessToast('已选赛类已保存!!');
      window.location.reload()
    }
    else {
      showFailToast("更新失败:"+ result.message);
    }
  }
  catch (error) {
    showFailToast('更新失败,http错误');

  }
  finally {
    loading.value=false
  }

};

const removeSelected = (index) => {
  selectedItems.value.splice(index, 1);
};

const searchLeagues = async () => {
  loadingAvailable.value = true;
  const result = await fetchLeagues(keyword.value);
  availableItems.value = result.map(item => ({
    ...item,
    checked: false,
  }));
  loadingAvailable.value = false;
};

const toggleSelection = (item) => {
  if (!item.checked) {
    item.checked=true;
    selectedItems.value.push(item);
  } else {
    const index =  selectedItems.value .findIndex(selected => selected.leagueId === item.leagueId);
    if (index !== -1) {
      item.checked=false;
      selectedItems.value.splice(index, 1);
    }
  }
};

const onLoad=async ()=>{
  loading.value = true;
  try {
    if(errorCount.value) return

    errorCount.value = errorCount.value + 1;
    const selectLeagueIds = await get("/AdminApi/Settings/GetFocalLeagueIds");
    selectedItems.value = [...selectLeagueIds];
    loading.value = false;
    finished.value = true;
  } catch (error) {
    loading.value = false;
    console.error('加载数据出错:', error);
  }
  finally {
    loading.value = false;
  }
}

const checkSelected=(item)=>{
  return selectedItems.value.findIndex(s => s.leagueId === item.leagueId)>=0;
}

onMounted(async () => {
  await onLoad();
  await searchLeagues()
});

const fetchLeagues = async (keyword) => {
  try {
    return (await get('/adminapi/league/GetList',{keyword: keyword})).data;
  } catch (error) {
    console.error('搜索联赛时出错:', error);
  }
  return [];
};
</script>

<style>
.container {
  background-color: #f9f9f9;
}

.selected-category, .available-category {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.selected-category{
  height: calc(100vh - 44px - 60vh);
}

.sub-content{
  padding: 0 16px 16px;
  flex-grow: 1;
  overflow-y: auto;
}

.available-category{
  height: calc(100vh - 40vh - 16px);
  margin-top: 16px;
  overflow: hidden;
}

.sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
}

.sub-header h3 {
  margin: 0
}

.van-button {
  margin-left: 8px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.selectedLeague{
  color: red;
}
</style>
