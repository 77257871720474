<template>
  <div class="page_box">
    <div class="content_box select-box">
      <ListItem mode="delete"></ListItem>
    </div>
    <div class="content_box list-box">
      <ListItem mode="confirm"></ListItem>
    </div>
    <div class="submit-bar">
      <div class="submit-bar__bar">
        <van-checkbox v-model="isChecked">全选</van-checkbox>
        <span style="flex:1"></span>
        <van-button round type="primary">确认</van-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ListItem from "@/components/ListItem.vue";

const isChecked = ref(false)
</script>

<style scoped>
.page_box{
  height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
}
.content_box {
	flex: 1;
	overflow-y: auto;
}
.submit-bar{
  position: relative;
  -webkit-user-select: none; 
  user-select: none;
  width: 100%;
  background-color: #fff;
  padding-bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.submit-bar__bar{
  height: 50px;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #f5f5f5;
}
.list-box{
  background-color: #fff;
}

</style>