import { createApp } from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import { loadRuntimeConfig } from './utils/request'; // 导入 loadRuntimeConfig
import router from './router'; // 引入 router
import {Toast} from 'vant';

import '@/assets/main.css';

(async () => {
    // 确保在加载应用前完成加载 /runtime.json
    await loadRuntimeConfig();

    const app = createApp(App);
    app.use(router); // 注册路由
    app.use(Vant);
    app.use(Toast);
    app.mount('#app');
})();

