<template>
  <div class="custom-item" @click="handleClick">
    <div class="custom-item-title">
      <slot name="title"></slot>
    </div>
    <div class="custom-item-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardView',
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.custom-item {
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.custom-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.custom-item-title {
  background-color: #f5f7fa;
  padding: 16px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
}

.custom-item-content {
  padding: 16px;
  font-size: 14px;
  color: #666;
}
</style>
