<template>
  <router-view />
</template>

<script setup>
import { onMounted } from 'vue';
import { isLoggedIn } from '/src/utils/auth'; // 假设auth.js中有相关函数
import { useRouter } from 'vue-router';

const router = useRouter(); // 确保这里正确导入

console.log(router)

onMounted(async () => {
  // 检查用户是否登录
  if (!isLoggedIn()) {
    await router.push('/login');
  } else {
    // 加载runtime.json中的默认host
  }
});
</script>

<style>
/* 全局样式 */
html, body, #app {
  height: 100%;
  background-color: #f5f5f5;
}
</style>
