import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/index/home.vue';
import Login from '@/views/index/login.vue';
import QTList from '@/views/missMapping/QTMissList.vue';
import HGList from '@/views/missMapping/HGMissList.vue';
import TTList from '@/views/missMapping/TTMissList.vue';
import FocalLeagueSetting from "@/views/settings/FocalLeagueSetting.vue";
import LeagueList from "@/views/league/LeagueIndex.vue";
import LeagueDetail from "@/views/league/LeagueDetail.vue";
import TeamIndex from "@/views/team/TeamIndex.vue";
import TeamDetail from "@/views/team/TeamDetail.vue";
import LeagueRelationSearch from "@/views/league/LeagueRelationSearch.vue";
import TeamRelationSearch from "@/views/team/TeamRelationSearch.vue";
import Bet365MissList from "@/views/missMapping/Bet365MissList.vue";

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/login', component: Login },
    { path: '/home', component: Home, meta: { requiresAuth: true } },
    { path: '/miss/qtlist', component: QTList, meta: { requiresAuth: true } },
    { path: '/miss/hglist', component: HGList, meta: { requiresAuth: true } },
    { path: '/miss/bet365list', component: Bet365MissList, meta: { requiresAuth: true } },

    { path: '/miss/ttlist', component: TTList, meta: { requiresAuth: true } },
    { path: '/league/index', component: LeagueList, meta: { requiresAuth: true } },
    { path:'/league/detail', component: LeagueDetail, meta: { requiresAuth: true,hidden: true } },
    {path: '/league/relationsearch', component: LeagueRelationSearch, meta: { requiresAuth: true } },
    { path: '/team/index', component: TeamIndex, meta: { requiresAuth: true } },
    { path:'/team/detail', component: TeamDetail, meta: { requiresAuth: true,hidden: true } },
    {path: '/team/relationsearch', component: TeamRelationSearch, meta: { requiresAuth: true } },

    { path: '/settings/focalSetting', component: FocalLeagueSetting, meta: { requiresAuth: true } },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!token) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
