import axios from 'axios';

let defaultHost = ''; // 用于保存从 /runtime.json 获取的 host

// 动态加载 /runtime.json 获取 host 参数
export const loadRuntimeConfig = async () => {
    try {
        const response = await fetch('/runtime.json');
        const config = await response.json();
        defaultHost = config.baseUrl || ''; // 从 runtime.json 获取 host
    } catch (error) {
        console.error('加载 runtime.json 失败:', error);
    }
};

// 初始化时加载 runtime.json
await loadRuntimeConfig();

// 创建 axios 实例
const instance = axios.create({
    baseURL: defaultHost, // 设置初始 baseURL
    timeout: 5000,
});

// 请求拦截器：在请求发送前添加 Token，并处理 baseURL
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // 如果请求的 URL 不以 'http' 开头，使用默认的 baseURL
        if (!/^https?:\/\//i.test(config.url)) {
            config.baseURL = defaultHost; // 如果不是完整 URL，则使用默认的 host
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => response.data, // 直接返回数据部分
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

// 通用请求方法封装
const request = async (options) => {
    const { method = 'get',  params, data } = options;

    // 处理 GET 请求：将 params 拼接到 url
    if (method.toLowerCase() === 'get' && params) {
        //const queryString = new URLSearchParams(params).toString();
        //options.url = `${url}?${queryString}`;
    }

    // 处理 POST 请求：自动格式化为 JSON
    if (method.toLowerCase() === 'post' && data) {
        options.headers = {
            'Content-Type': 'application/json',
            ...options.headers,
        };
    }

    try {
        return await instance(options); // 返回数据部分
    } catch (error) {
        console.error('请求出错：', error);
        throw error; // 抛出错误
    }
};

// 封装 POST 请求函数
export const post = async (url, data, config = {}) => {
    return request({
        url,
        method: 'post',
        data,
        ...config,
    });
};

// 封装 GET 请求函数
export const get = async (url, params, config = {}) => {
    return request({
        url,
        method: 'get',
        params,
        ...config,
    });
};

export default { request, post, get };
