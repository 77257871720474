<template>
  <div class="grid-container">
    <van-grid :column-num="3" gutter="8" class="custom-grid">
      <van-grid-item
          v-for="(item, index) in gridItems"
          :key="index"
          :text="item.text"
          @click="navigateTo(item.url)"
          class="grid-item"
      >
        <template #icon>
          <van-icon :name="item.icon" class="grid-icon" />
        </template>
      </van-grid-item>
      <template v-if="gridItems.length < 9">
        <van-grid-item  v-for="n in (9 - gridItems.length)" :key="'placeholder-' + n" class="grid-item placeholder"></van-grid-item>
      </template>

    </van-grid>
  </div>
</template>


<script>
import {ref} from 'vue';
import {useRouter} from 'vue-router';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter();

    // 九宫格项目数据
    const gridItems = ref([
      {text: 'QT缺失列表', icon: 'home-o', url: '/miss/qtlist'},
      {text: 'HG缺失列表', icon: 'search', url: '/miss/hglist'},
      {text:'365缺失列表', icon: 'search', url: '/miss/bet365list'},
      {text: '焦点赛类设置', icon: 'search', url: '/settings/focalSetting'},
      //{text:'赛类列表',icon: 'search', url: '/league/index'},
      {text: '赛类关联查询', icon: 'search', url: '/league/relationsearch'},
      {text:'队伍列表',icon: 'search', url: '/team/index'},
      {text:'队伍关联查询',icon: 'search', url: '/team/relationsearch'},

      //{text: 'TT缺失列表', icon: 'fire', url: '/miss/ttlist'},
    ]);

    // 导航到指定页面
    const navigateTo = (url) => {
      router.push(url);
    };

    return {
      gridItems,
      navigateTo
    };
  }
};
</script>
<style scoped>
.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  padding: 2px;
}

.grid-wrapper {
  width: 100%;
  max-width: 900px; /* 可以根据需要调整 */
}

.custom-grid {
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 2px; /* 添加内边距 */
}

.grid-item {
  border-radius: 12px;
}

.grid-item >>> .van-grid-item__content{
  border-radius: 12px;
  padding: 36px 28px;
  background-color: #fff;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.grid-item:hover >>> .van-grid-item__content{
  background-color: #e0f7fa;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.grid-icon {
  font-size: 40px;
  color: #007bff;
  margin-bottom: 12px;
}

.grid-item .van-grid-item__text {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.placeholder {
  background-color: transparent; /* 占位元素透明 */
  border: none; /* 去掉边框 */
  box-shadow: none; /* 去掉阴影 */
}
</style>