<template>
  <div class="page-container">
    <!-- 标题栏 -->
    <div class="header">
      <van-icon name="arrow-left" class="icon-back" @click="goBack" />
      <div class="title-text">{{getKeywordType()}}队伍列表</div>
      <van-icon name="home-o" class="icon-home" @click="goHome" />
    </div>
    <div class="search-bar">

      <van-search v-model="searchKeyword" @search="searchTeams" clearable placeholder="搜索队伍" class="custom-search" style="padding: 0;flex: 1;" />

      <van-button type="primary" size="small" @click="openDialog(null)" style="margin-left: 5px;">新增</van-button>
    </div>

    <van-list
        class="content"
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <card-view v-for="item in teams"
                 :key="item.teamId"
                 @click="openDialog(item)"
      >
        <template #title>
          <div class="card-title">
          <span>{{item.teamId}}:{{item.twName}}</span>

          <van-icon
              name="delete"
              class="delete-icon"
              @click.stop="deleteTeam(item.teamId)"
          />
          </div>
        </template>

        <van-field v-model="item.cnName" label="简体名称" readonly />
        <van-field v-model="item.twName" label="繁体名称" readonly />
        <van-field v-model="item.enName" label="英文名称" readonly />
        <van-field v-model="item.thName" label="泰文名称" readonly />
        <van-field :label="getKeywordType()" v-model="item.relationName" readonly v-if="searchType!=='-1'" />
      </card-view>
      <van-divider />
    </van-list>

  </div>
</template>

<script setup>
import { ref } from 'vue';
import {get, post} from '/src/utils/request'
import CardView from "@/components/CardView.vue";
import {showConfirmDialog, showFailToast, showSuccessToast} from "vant";

import {useRoute, useRouter} from 'vue-router';

const router = useRouter();
const route=useRoute()
//const isRefreshing=ref(false);

const teams = ref([]);
const loading = ref(false);
const finished = ref(false);
const searchKeyword=ref(route.query?.keyword??"");
const searchType=ref(route.query?.type??-1);
const errorCount=ref(0)
/*
const onRefresh=async ()=>{
  isRefreshing.value = true;
  try {
    await searchTeams();
    errorCount.value=0;
    finished.value = true;
  } catch (error) {
    isRefreshing.value = false;
    console.error('加载数据出错:', error);
  }
  finally {
    isRefreshing.value = false;
  }
} */


const onLoad = async () => {
  loading.value = true;
  try {
    if(errorCount.value) return

    errorCount.value = errorCount.value + 1;
    await searchTeams();
    loading.value = false;
    finished.value = true;
  } catch (error) {
    loading.value = false;
    console.error('加载数据出错:', error);
  }
  finally {
    loading.value = false;
  }
};

const openDialog = (match) => {
  if (match){
    router.push("/team/detail?teamId="+match.teamId)
  }
  else {
    router.push("/team/detail")
  }

};


const searchTeams = async () => {
  //loadingAvailable.value = true;
  const result = await fetchTeams(searchKeyword.value,searchType.value);
  teams.value = result.map(item => ({
    ...item,
    relationName:getMappingName(item),
    checked: false,
  }));
  //loadingAvailable.value = false;
};

const fetchTeams = async (keyword,type=-1) => {
  try {
    return (await get('/adminapi/team/GetList',{keyword: keyword,keywordType:type})).data;
  } catch (error) {
    console.error('搜索队伍时出错:', error);
  }
  return [];
};


const deleteTeam=async (item)=>{
  if (!item){
    return;
  }

  if(await showConfirmDialog({
    showCancelButton:true,
    showConfirmButton:true,
    message:`确定要删除${item.twName}吗?` ,
    title:"删除确认"
  })){
    loading.value=true;

    try {
      let ret=await post('/adminapi/team/DeleteTeam',{teamId: item.teamId})
      loading.value=false;

      if (!ret){
        showFailToast("请求失败");
      }
      else {
        if (ret.isSuccess){
          showSuccessToast("操作成功")
          await searchTeams();
        }
        else {
          showFailToast("操作失败:" + ret.message)
          await searchTeams();
        }
      }
    }
    catch (e) {
      loading.value=false;

      showFailToast("请求失败2:" + e.message);
    }
  }
}

const getKeywordType=()=>{
  switch (searchType.value) {
    case '1':{
      return "QT"
    }
    case "2":{
      return "HG"
    }
    case "3":{
      return "365";
    }
  }
  return ""
}

const getMappingName=(row)=>{
  return row.relationships.filter(x=>x.source===parseInt(searchType.value)-1).map(x=>`${x.sourceId}-${x.sourceName}`).join('\n')

}

const goBack=()=> {router.go(-1);};

function goHome(){router.push('/');}
</script>

<style scoped>
.search-bar {
  display: flex;
  align-items: center;
  padding: 10px;
}
.nomatch{
  color:red;
}

.custom-search {
  flex: 1;
  padding: 0;
  border: 1px solid #ccc; /* 添加边框 */
  border-radius: 4px;      /* 圆角 */
  background-color: #f9f9f9; /* 背景颜色 */
  padding: 5px;            /* 内边距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
}

.custom-search .van-field__control {
  background-color: transparent; /* 控制输入框的背景色 */
  padding-left: 8px;             /* 内边距 */
}

.custom-search .van-field__clear {
  margin-right: 8px;             /* 清除按钮的内边距 */
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-icon {
  color: red;
  cursor: pointer;
}
</style>
